




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ChainLogo extends Vue {
  @Prop({ default: 'bsc' }) chain!: 'bsc' | 'eth' | 'sol'
}
